module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Printlitic","short_name":"Printlitic","start_url":"/","background_color":"#fff","theme_color":"#f85a40","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7561825cb25aca31d1f0024009ead1fa"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://printlitic.com","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../../../packages/gatsby-theme-seomass/gatsby-browser.js'),
      options: {"plugins":[],"siteMetadata":{"siteTitle":"Printlitic","siteDescription":"Compare the top 3D Printers!","siteUrl":"https://printlitic.com","siteSubject":"Printers","siteSubjectSlug":"printers","siteLanguage":"en","siteImage":"/banner.jpg","siteTheme":{"mainColor":"#f85a40","secondColor":"#bc4431","thirdColor":"#6f281d"},"siteNavigation":[{"name":"3D Printers","slug":"/printers/","submenu":""},{"name":"Blog","slug":"/blog/"},{"name":"About","isCustomPage":true,"slug":"/about/","staticSubMenu":[{"name":"Contact us","slug":"/contact/"}]}]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-windicss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
